// 应用详情
<template>
  <div class="application-details-wrapper">
    <div class="details-bg-wrapper">
      <div class="details-bg wrap-v1">
        <div class="title ">{{ details.title }}</div>
        <div class="sub-title ">{{ details.subtitle }}</div>
      </div>
    </div>

    <div class="application-details wrap-v1">
      <img class="application-img" :src="details.bgUrl" alt="" />
      <div class="title">产品概述</div>
      <div class="sub-title">
        {{ details.desc }}
      </div>
      <div class="title">产品背景</div>
      <div class="cpbg">
        <div
          class="item"
          v-for="(item, index) in details.usedList"
          :key="index"
        >
          <img :src="item.imgUrl" alt="" />
          <div class="text">{{ item.name }}</div>
        </div>
      </div>
      <div class="title">产品功能</div>
      <div class="cpgn">
        <div
          class="item"
          v-for="(item, index) in details.function"
          :key="index"
        >
          <div class="t1">{{ item.name }}</div>
          <span class="line"></span>
          <div class="t2"></div>
        </div>
      </div>
      <Advantage
        title="核心技术优势"
        :advantage="details.advantage"
      ></Advantage>
      <div class="title w9 mt12">
        {{ details.subtitle }}
      </div>
    </div>
  </div>
</template>
<script>
import Advantage from "@/components/advantage.vue";
import product from "./product";
export default {
  components: { Advantage },
  data() {
    return {
      details: {}
    };
  },
  created() {
    this.details = product.filter(item => {
      if (item.id == this.$route.params.id) return item;
    })[0];
  },
  mounted() {
    let page = document.querySelector("#app");
    if (page.scrollTop > 0) {
      page.scrollTop = 0;
    }
  }
};
</script>
<style scoped lang="scss">
.w9 {
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
}
.mt12 {
  margin-top: 120px;
}
.application-details-wrapper {
  .details-bg-wrapper {
    height: 250px;
    background-color: #007bb9;
    color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    .details-bg {
      flex: 1;
      .title {
        font-size: 52px;
      }
      .sub-title {
        margin-top: 20px;
        font-size: 36px;
      }
    }
  }
  .application-details {
    padding: 80px 0 100px;
    .application-img {
      width: 100%;
      max-width: 1200px;
      height: 450px;
      margin-bottom: 80px;
    }
    .title {
      font-size: 50px;
      color: #333333;
      line-height: 70px;
    }
    .sub-title {
      margin-top: 40px;
      margin-bottom: 80px;
      font-size: 20px;
      color: #333333;
      line-height: 40px;
      font-weight: 300px;
      text-align: left;
    }
    .cpbg {
      margin-top: 60px;
      margin-bottom: 90px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
        width: 387px;
        img {
          /* width: 387px; */
          width: 100%;
          height: 250px;
        }
        .text {
          font-size: 26px;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          padding: 55px 0;
        }
      }
    }
    .cpgn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 60px;
      margin-bottom: 80px;
      .item {
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
        width: 290px;
        height: 380px;
        .t1 {
          width: 100%;
          text-align: center;
          margin: 100px 0 80px;
          height: 42px;
          font-size: 30px;
          line-height: 42px;
          color: #333;
        }
        .line {
          height: 8px;
          width: 15%;
          display: inline-block;
          background-color: rgb(0, 123, 185);
          margin-bottom: 20px;
        }
        .t2 {
          padding: 0 20px;
          text-align: left;
          height: 84px;
          font-size: 15px;
          line-height: 21px;
          color: #777;
        }
      }
    }
  }
}
</style>
